@function get-vw($viewport, $pixels) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: ($viewport * 0.01) * 1px; // 1% viewport width
  @return ($pixels/$vw-context) * 1vw;
}

@mixin large-screens {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin small-screens {
  @media (max-width: 899px) {
    @content;
  }
}