@import "mixins";

.tag {
  float: left;
  margin: get-vw(1440, 2.5px);
  padding: get-vw(1440, 5px) get-vw(1440, 10px);
  color: #fff;
  cursor: pointer;
  position: relative;
  font-size: get-vw(1440, 48px);
  line-height: 1.14em;
  background-color: #020839;
  border-radius: get-vw(1440, 5px);

  @include small-screens {
    margin: get-vw(900, 2.5px);
    padding: get-vw(900, 5px) get-vw(900, 10px);
    font-size: get-vw(900, 55px);
    border-radius: get-vw(900, 5px);
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.7) 0px 2px 5px;
  }

  &--client {
    padding: get-vw(1440, 5px) get-vw(1440, 20px);
    border-radius: get-vw(1440, 48px);

    @include small-screens {
      padding: get-vw(900, 5px) get-vw(900, 20px);
      border-radius: get-vw(900, 55px);
    }
  }

  &.is-active {
    background-color: #FD0505;
    color: white;
  }
}