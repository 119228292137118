@import "mixins";

@font-face {
  font-family: 'FK Raster Roman Compact-Smooth';
  src: url('/fonts/FKRasterRomanCompact-Smooth.woff2') format('woff2'),
      url('/fonts/FKRasterRomanCompact-Smooth.woff') format('woff');
  font-weight: normal;
}

::selection {
  background-color: black;
  color: white;
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  color: #000;
  padding: 0;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;

  @include small-screens {
    background-color: #000;
  }

  .side {
    width: 50%;

    @include small-screens {
      width: 100%;
    }

    &:last-child {
      position: fixed;
      right: 0;
      height: 100%;
      background-color: #d9d9d9;
      z-index: 2;

      @include small-screens {
        display: none;
      }

      @include large-screens {
        top: 0;
      }
    }
  }
}

a {
  color: black;
  text-decoration: none;
  text-decoration-thickness: 0.5px;

  &:hover {
    text-decoration: none;
  }
}

.projects-list {
  list-style: none;
  padding: get-vw(1440, 20px) get-vw(1440, 20px) get-vw(1440, 120px);
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: get-vw(1440, 77px);
  line-height: 1em;

  @include small-screens {
    padding: 130px get-vw(900, 20px) 200px;
    font-size: get-vw(900, 130px);
  }
}

.tags-list {
  list-style: none;
  padding: get-vw(1440, 20px) get-vw(1440, 20px);
  margin: 0;
  width: 100%;

  @include small-screens {
    display: none;
  }
}

.details {
  font-size: 15px;
  position: fixed;
  z-index: 3;
  color: black;
  display: flex;
  align-items: flex-end;
  width: 100%;

  @include small-screens {
    line-height: 1em;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    padding: 20px;
    border-bottom: 0.5px solid #333;
    background-color: #000;

    a {
      color: #fff;
    }
  }

  @include large-screens {
    bottom: 20px;
    left: 20px;
  }

  .contact,
  .active-tag-info {
    flex: 1;
  }

  .active-tag-info {
    display: flex;
    align-items: center;

    svg {
      width: 11px;
      height: 11px;
      margin: 0 4px;
    }

    @include small-screens {
      display: none;
    }
  }

  span {
    display: block;
  }

  a {
    text-decoration: underline;
    text-decoration-thickness: 0.8px;
    text-underline-offset: 2.2px;
  }

  .active-tag-link {
    text-decoration: none;
    display: inline-block;
    padding: 1px 8px 2px;
    margin: 0 2px;
    border-radius: 20px;
    background-color: #FFF;
    color: #000;

    &:hover {
      background-color: #020839;
      color: #fff;
    }

    @include small-screens {
      display: none;
    }
  }
}

