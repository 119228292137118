@import "mixins";

.project {
  font-family: 'FK Raster Roman Compact-Smooth', sans-serif;

  a {
    line-height: 0.96em;
    color: #00F;

    @include small-screens {
      color: #fff;
    }
  }

  svg {
    display: inline-block;
    vertical-align: top;
    width: get-vw(1440, 20px);
    height: get-vw(1440, 20px);
    opacity: 0;

    @include small-screens {
      display: none;
    }
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}